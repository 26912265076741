<template>
    <div class="pages">
        <pageHeader></pageHeader>
        <div class="box">
            <div class="main">
                <div class="left">
                    <div class="left-item" :class="typeIdx === index ? 'left-item-active' : ''"
                        v-for="(item, index) in typeLists" :key="index" @click="changeType(index)">{{ item }}</div>
                </div>
                <div class="right" v-html="content"></div>
            </div>
        </div>
        <pageFooter></pageFooter>
    </div>
</template>

<script>
import { getPrivacyPolicy, getUserAgreement } from '@/api/login.js'
import { getPayAgreement } from '@/api/pay.js'
import pageHeader from '@/components/pageHeader.vue'
import pageFooter from '@/components/pageFooter.vue'
export default {
    name: "college",
    components: {
        pageHeader,
        pageFooter
    },
    data() {
        return {
            typeLists: ['用户服务协议', '隐私政策协议', '付费协议'],
            typeIdx: null,
            content: ''
        }
    },
    created() {
        this.typeIdx = this.$route.query.type - 1
        this.getProtocol()
    },
    methods: {
        /**
         * 切换协议类型
         */
        changeType(index) {
            this.typeIdx = index
            this.getProtocol()
        },
        /**
         * 获取协议
         */
        getProtocol() {
            if (this.typeIdx == 0) {
                getUserAgreement().then(res => {
                    this.content = res
                })
            } else if (this.typeIdx == 1) {
                getPrivacyPolicy().then(res => {
                    this.content = res
                })
            } else if (this.typeIdx == 2) {
                getPayAgreement().then(res => {
                    this.content = res
                })
            }
        }
    }
}
</script>

<style scoped>
.pages {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: calc(100vh - 120px);
    background-color: #F7F7F8;
}

.main {
    width: 1200px;
    min-height: calc(100vh - 152px);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 16px;
    margin-bottom: 16px;
}

.left {
    width: 296px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 0;
}

.left-item {
    width: 296px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 24px;
    color: #595959;
    font-size: 14px;
    position: relative;
    cursor: pointer;
}

.left-item-active {
    background-color: rgba(1, 64, 153, 0.03);
    color: #DD0722;
}

.left-item-active::after {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    width: 2px;
    height: 54px;
    background-color: #DD0722;
}

.right {
    width: 888px;
    background-color: #ffffff;
    padding: 32px;
}
</style>
